import axios from "./AxiosWrapper";
import config from "../config";

const DiscoverNSWService = {
  async add(voucherCode, orderId) {
    try {
      const response = await axios.post(`${config("API_URL")}payments/discover-nsw-voucher/add`, {
        voucherCode,
        orderId
      });
      return response.data;
    } catch (err) {
      throw err.response.data;
    }
  },
  async remove(orderId) {
    try {
      const response = await axios.post(`${config("API_URL")}payments/discover-nsw-voucher/remove`, {
        orderId
      });
      return response.data;
    } catch (err) {
      throw err.response.data;
    }
  }
};

export default DiscoverNSWService;
